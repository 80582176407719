<template>
    <div>
        <div class="ct_box">
            <ul class="flex ul1">
                <li :class="category_id == item.id ? 'active' : ''" v-for="(item, index) in $store.state.UserMediaNav"
                    :key="index" @click="tab_show(item.id)">
                    {{ item.title }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        category_id: Number,
    },
    mounted() {
        
    },
    computed: {
        ...mapState(['UserMediaNav', 'UserMedia', 'UserTypeItem', 'FillForm'])
    },
    data() {
        return {
        }
    },
    methods: {
        tab_show(id) {
            // if (id) {
            
        }
    },
}
</script>

<style lang=scss scoped>
.ul1 {
    padding: 1rem 0;
    align-items: center;

    li {
        padding: .5rem 0.7rem;
        margin-right: 15px;
        font-size: 1.2rem;
        transition: 0.2s all;
        border-radius: 0.5rem;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;

        &:hover {
            color: #168ad9;
        }
    }
}

// 被选中
.active {
    background: #108CDD;
    color: #fff !important;
    font-weight: 700;
}
</style>