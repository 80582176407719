<template>
  <div>
    <div class="box" @click.self="shopShow = false" v-loading.fullscreen.lock="fullscreenLoading">
      <export_excel :json_data="json_data" :json_fields="json_fields" name="小红书.xls"/>
      <Title title="资源列表"/>
      <div class="table_box">
        <table>
          <thead>
          <td>媒介名称</td>
          <td>小红书号</td>
          <td>图文价格</td>
          <td>视频价格</td>
          <td>行业类型</td>
          <td>所属区域</td>
          <td>图文阅读量</td>
          <td>视频播放量</td>
          <td>粉丝数</td>
          <td>受众性别</td>
          <td>受众年龄</td>
          <td>备注</td>
          <td>操作</td>
          </thead>
          <tbody>
          <tr v-for="(item, index) in tableData" :key="index">
            <td>
              <td_remark :text="item.title"/>
            </td>
            <td>
              <td_remark :text="item.account"/>
            </td>
            <td>
              <button :class="item.check == 'member_image_text_price' ? 'priceActive' : 'price'"
                      @click.stop="onPitch(index, 'member_image_text_price')">￥0
              </button>
            </td>
            <td>
              <button :class="item.check == 'member_video_price' ? 'priceActive' : 'price'"
                      @click.stop="onPitch(index, 'member_video_price')">￥0
              </button>
            </td>
            <td>
              <td_remark :text="item.industry_classify_title"/>
            </td>
            <td>{{ item.area_title }}</td>
            <td>{{ item.image_text_views }}</td>
            <td>{{ item.video_views }}</td>
            <td>{{ item.fans_num }}</td>
            <td>{{
                item.audience_sex == 0 ? '不限' : item.audience_sex == 1 ? '女性粉丝偏多' :
                    item.audience_sex == 2 ? '男性粉丝偏多' : '男女粉丝均衡'
              }}
            </td>
            <td>{{
                item.audience_age == 0 ? '不限' : item.audience_age == 1 ? '0-17岁偏多' :
                    item.audience_age == 2 ? '18-24岁偏多' : item.audience_age == 3 ? '25-34岁偏多' :
                        item.audience_age == 4 ? '35-44岁偏多' : '44岁以上偏多'
              }}
            </td>
            <td>
              <td_remark :text="item.remark"/>
            </td>
            <td>
              <div class="collect_box flex" style="justify-content: center;align-items: center;">
                <p @click.stop="jumpLink(item)">查看案例</p>
                <!-- v-if="item.collect == 0"  -->
                <!--                <p v-if="item.is_collect == 0" class="collect" @click.stop="collectGet(item)">收藏</p>-->
                <!--                &lt;!&ndash; v-if="item.collect == 1" &ndash;&gt;-->
                <!--                <p v-if="item.is_collect == 1" class="collect2" @click.stop="collectClose(item)">已收藏-->
                <!--                </p>-->
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <!-- 分页器 -->
        <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>
      </div>
    </div>
    <!-- 购物车 -->
    <div v-show="shopShow == false" class="shopping_car">
      <div class="car" @click.stop="shopShow = true">
        <p>{{ CarList.length }}</p>
      </div>
    </div>
    <div :class="shopShow ? 'shop_box' : 'shop_none_box'">
      <div class="title flex" style="justify-content: space-between; align-items: center">
        <h3 class="carTitle">已选媒体: {{ CarList.length }}</h3>
        <button class="empty_but" v-show="CarList.length != 0" @click="empty()">
          清空已选媒体
        </button>
        <span class="close" @click="shopShow = false"></span>
      </div>
      <ul class="listbox" v-show="CarList.length != 0">
        <li v-for="(item, index) in CarList" :key="index">
          <!-- <img :src="item.logo" alt="" /> -->
          <div style="width:100px">
            <td_remark :text="item.title"/>
          </div>
          <p class="price">{{
              item.check == 'member_image_text_price' ?
                  `图文价格:￥0` :
                  `视频价格:￥0`
            }}</p>
          <span class="deleteIcon" @click.stop="detale(item)"></span>
        </li>
      </ul>
      <el-empty v-show="CarList.length == 0" description="您还没有选择资源赶紧去看看心仪的资源吧"></el-empty>
      <div class="bottom_box">
        <div class="flex" style="justify-content: space-between; margin-bottom: 1rem">
          <p style="font-size: 1.4rem; color: #999">合计：</p>
          <p class="totalMoney">
            ￥<span>0</span>
          </p>
        </div>
        <button class="put_but" @click="putFn(item)">立即投放</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import export_excel from '@/components/export_excel.vue';
import media_screen from '@/components/media_screen.vue';
import {media_screen_} from '@/util/media_screen_'

export default {
  computed: {
    ...mapState(['UserMedia', 'MediaNav', 'UserTypeItem', 'fillActive']),
    totalMoney: function () {
      var n = 0;
      for (var i = 0; i < this.CarList.length; i++) {
        if (this.CarList[i].check == 'member_image_text_price') {
          // this.CarList.push(this.tableData[i])
          n += this.CarList[i].member_image_text_price;
        } else if (this.CarList[i].check == 'member_video_price') {
          // this.CarList.push(this.tableData[i])
          n += this.CarList[i].member_video_price;
        }
      }
      return n.toFixed(2);
    }
  },
  watch: {
    CarList: {
      handler() {
        this.excelPush()
      },
      deep: true,
    }
  },
  components: {
    media_screen,
    export_excel
  },
  data() {
    return {
      /*数据表格*/
      tableData: [],
      collect: 0,
      CarList: [],
      // 购物车任务栏切换
      shopShow: false,
      Allcheck: false,
      AllcheckNum: 0, //选中总数
      fullscreenLoading: false,
      total_page: 0, //分页总页数
      page: 1,
      count: 1,
      json_fields: {
        "小红书号": 'title',
        '账户': 'account',
        "图文价格": 'member_image_text_price',
        "视频价格": 'member_video_price',
        "图文阅读量": 'image_text_views',
        "案例链接": 'case_url',
        "所属区域": 'area_title',
        "可发内链": 'is_pub_url',
        "可发联系方式": 'is_pub_contact',
        "价格": 'price',
        "粉丝数": 'fans_num',
        "受众性别": 'audience_sex',
        "受众年龄": 'audience_age',
        "备注": 'remark',
      },
      json_data: [],
      //请求参数
      data: {
        category_id: 6,
        collect: 0,
        page: 1,
        limit: 20,
        // is_right_package: 1
      }
    }
  },
  mounted() {
    this.get_list()
  },
  methods: {
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    onPitch(index, type) {
      this.shopShow = true
      console.log('tableDate:', index, type);
      // this.tableData[index].check = type
      const result = this.CarList.every(item => item.id !== this.tableData[index].id);
      if (result == true) {
        this.tableData[index].check = type
        this.CarList.push(this.tableData[index])
      } else {
        this.CarList.forEach((item, i) => {
          if (this.CarList[i].id == this.tableData[index].id && this.CarList[i].check != type) {
            this.tableData[index].check = type
            this.CarList[i].check = type
          } else if (this.CarList[i].id == this.tableData[index].id && this.tableData[index].check == type) {
            this.CarList.splice(i, 1)
            this.tableData[index].check = ''
          }
        })
      }
    },
    // 清空购物车
    empty() {
      this.$confirm('是否清空已选媒体?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            this.fullscreenLoading = true
            // setTimeout(() => {
            this.CarList = []
            this.shopShow = false
            // this.toggleSelection()
            this.tableData.forEach((item, index) => {
              if (item.check) {
                console.log(item)
                return (item.check = false)
              }
            })
            this.fullscreenLoading = false
            // }, 500)
            this.$message({
              type: 'success',
              message: '已清空已选媒体',
            })
          })
          .catch(() => {
            this.$message({
              type: 'warning',
              message: '已删除',
            })
          })
    },
    createBall(left, top) {
      const bar = document.createElement('ball')
      bar.style.position = 'fixed'
      bar.style.left = left + 'px'
      bar.style.top = top + 'px'
      bar.style.width = '20px'
      bar.style.height = '20px'
      bar.style.borderRadius = '50%'
      bar.style.backgroundColor = 'red'
      bar.style.transition =
          'left 1s linear, top 1s cubic-bezier(0.5, -0.5, 1, 1)'
      document.body.appendChild(bar)
      setTimeout(() => {
        const x = document.body.clientWidth * 1.5
        const y = document.body.clientHeight * 1 - 500
        bar.style.top = y + 'px'
        console.log(y)
        bar.style.left = x + 'px'
      }, 0)
      bar.ontransitionend = function () {
        this.remove()
      }
    },
    // 获取列表数据
    get_list() {
      // let data = media_screen_(this.$store.state.media_screen_data.date_form)
      // data.page = this.page
      // data.limit = 20
      this.fullscreenLoading = true
      this.curlGet('/api/medium/right_package_list', this.data).then((res) => {
        if (res.data.code) {
          this.tableData = res.data.data.list
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
          this.tableData.forEach((item, index) => {
            this.$set(this.tableData[index], 'check', '')
            this.CarList.forEach((el, i) => {
              if (el.check && item.id == el.id) {
                this.$set(this.tableData[index], 'check', el.check)
              }
            })
          })
          this.shopDate(this.tableData, this.$store.state.shopObj, this.CarList)
        }
        this.fullscreenLoading = false
      })
    },
    excelPush() {
      this.json_data = []
      this.CarList.forEach((item, index) => {
        this.json_data.push({
          title: item.title,
          account: item.account,
          member_image_text_price: item.member_image_text_price,
          member_videoe_price: item.member_video_price,
          image_text_views: item.image_text_views,
          case_url: item.case_url,
          area_title: item.area_title,
          is_pub_url: item.is_pub_url ? '是' : '否',
          is_pub_contact: item.is_pub_contact ? '是' : '否',
          fans_num: item.fans_num,
          audience_sex: item.audience_sex ? '男' : '女',
          audience_age: item.audience_age == 0 ? '不限' : item.audience_age == 1 ? '0-17岁偏多' : item.audience_age == 2 ? '18-24岁偏多' : item.audience_age == 3 ? '25-34岁偏多' : item.audience_age == 4 ? '35-44岁偏多' : '44岁以上偏多',
          remark: item.remark
        })
      })
    },
    // 删除
    detale(element) {
      this.CarList.forEach((el, i) => {
        if (el.id == element.id) {
          this.CarList.splice(i, 1)
        }
      })
      // this.CarList.splice(index,1)
      this.tableData.forEach((item, index) => {
        if (item.id == element.id) {
          this.tableData[index].check = ''
        }
      })
    },
    // 立即投放
    putFn() {
      if (this.CarList.length == 0) {
        return this.$message({
          type: 'warning',
          message: '请选择媒介',
        })
      } else if (!this.$user_info) {
        this.$message({
          type: 'warning',
          message: '请登录',
        })
      } else {
        this.fullscreenLoading = true
        this.CarList.forEach((item, index) => {
          this.CarList[index].price = this.CarList[index][this.CarList[index].check]
          console.log(this.CarList[index]);
        })
        setTimeout(() => {
          this.fullscreenLoading = false
          this.$store.commit('shopPush2', this.CarList)
          if (this.$store.state.fillActive == false) {

            this.$router.push('/user/SetMenu/ManuscriptPreview')
          } else {
            this.$store.commit('childTab', '小红书发布')
            this.$router.push({
              path: '/user/SetMenu/fill?id=6',
            })
          }
        }, 500)
      }
    },
    // 收藏
    collectGet(item) {
      console.log(item);
      this.curlGet('/api/medium/collect', {
        mid: item.id,
        style: 1
      }).then(res => {
        console.log(res)
        if (res.data.code) {
          this.$message({
            message: '收藏成功',
            type: 'success'
          })
          this.get_list()
        }
      })
    },
    // 取消收藏
    collectClose(item) {
      this.$confirm('是否取消收藏?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlGet('/api/medium/collect', {
          mid: item.id,
          style: 1
        }).then(res => {
          console.log(res)
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: '取消收藏成功!'
            });
            this.get_list()
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });

    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/shopCar';
@import '@/scss/user_media';
@import '@/scss/price';
</style>