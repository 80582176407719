<script>
import OfficialAccount_list from "@/views/user/userBenefitPackage/officialAccount_list.vue";
import Weibo_list from "@/views/user/userBenefitPackage/weibo_list.vue";
import LittleRedBook_list from "@/views/user/userBenefitPackage/littleRedBook_list.vue";
import News_list from "@/views/user/userBenefitPackage/news_list.vue";
import PaperMedia_list from "@/views/user/userBenefitPackage/paperMedia_list.vue";
import ShortVideo_list from "@/views/user/userBenefitPackage/shortVideo_list.vue";
import WeMedia_list from "@/views/user/userBenefitPackage/weMedia_list.vue";

export default {
  components: {
    ShortVideo_list,
    PaperMedia_list, WeMedia_list, LittleRedBook_list, Weibo_list, OfficialAccount_list, News_list
  },
  data() {
    return {
      //el-tab高亮
      activeName: 'first',
    }
  },
  methods: {
    //tab回调
    handleClick(tab, event) {
      // console.log(tab, event);
    }
  }
};
</script>

<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="资讯门户权益包" name="first">
        <news_list></news_list>
      </el-tab-pane>
      <el-tab-pane label="自媒体权益包" name="second">
        <we-media_list/>
      </el-tab-pane>
      <el-tab-pane label="纸媒权益包" name="third">
        <paper-media_list></paper-media_list>
      </el-tab-pane>
      <el-tab-pane label="公众号权益包" name="fourth">
        <official-account_list/>
      </el-tab-pane>
      <el-tab-pane label="微博权益包" name="fifth">
        <weibo_list/>
      </el-tab-pane>
      <el-tab-pane label="小红书权益包" name="sixth">
        <little-red-book_list/>
      </el-tab-pane>
      <el-tab-pane label="短视频" name="seventh">
        <short-video_list></short-video_list>
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<style scoped lang="scss">
/* 修改自定义类名下标签页激活时的文字颜色 */
::v-deep .el-tabs__item.is-active {
  color: rgb(16, 140, 221);
}

/* 修改自定义类名下底部指示器的颜色 */
::v-deep .el-tabs__active-bar {
  color: rgb(16, 140, 221);
}

/*鼠标移入高亮*/
::v-deep .el-tabs__item:hover {
  color: rgb(16, 140, 221);
}
</style>