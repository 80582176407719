<template>
  <div>
    <div class="box" @click.self="shopShow = false" v-loading.fullscreen.lock="fullscreenLoading">
      <export_excel :json_data="json_data" :json_fields="json_fields" name="公众号.xls"/>
      <Title title="资源列表"/>
      <div class="table_box">
        <table>
          <thead>
          <tr>
            <td>媒介名称</td>
            <td>微信号</td>
            <td>头条价格</td>
            <td>普条价格</td>
            <td>行业类型</td>
            <td>所属区域</td>
            <td>参考粉丝数</td>
            <td>平均浏览量</td>
            <!-- <td>截稿时间</td>
            <td>出稿速度</td> -->
            <td>可发内链</td>
            <td>可发联系方式</td>
            <td>是否官方</td>
            <td>备注(仅供参考)</td>
            <td>操作</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in tableData" :key="index">
            <td>
              <td_remark :text="item.title"/>

            </td>
            <td>
              {{ item.account }}
            </td>
            <td>
              <button :class="item.check == 'member_headline_price' ? 'priceActive' : 'price'"
                      @click.stop="onPitch(index, 'member_headline_price')">
                ￥0
              </button>
            </td>
            <td>
              <button :class="item.check == 'ordinary_price' ? 'priceActive' : 'price'"
                      @click.stop="onPitch(index, 'ordinary_price')">
                ￥0
              </button>
            </td>
            <td>{{ item.industry_classify_title }}</td>
            <td>{{ item.area_title }}</td>
            <td> {{ item.fans_num }}</td>
            <td>{{ item.views }}</td>
            <!-- <td>{{ item.stop_draft_time == 0 ? '无' : item.stop_draft_time + ':00' }}</td>
              <td>
                {{
    item.draft_speed == 0
      ? '不限'
      : item.draft_speed == 1
        ? '1小时'
        : item.draft_speed == 2
          ? '2小时'
          : item.draft_speed == 3
            ? '3小时'
            : item.draft_speed == 4
              ? '4小时'
              : item.draft_speed == 5
                ? '当天'
                : '次日'
  }}
              </td> -->
            <td :style="{
			color: item.is_pub_link == 1 ? '#55C964' : '#FF0000',
		}">
              {{ item.is_pub_link == 0 ? '否' : '是' }}
            </td>
            <td :style="{
			color: item.is_pub_contact == 1 ? '#55C964' : '#FF0000',
		}">
              {{ item.is_pub_contact == 0 ? '否' : '是' }}
            </td>
            <td :style="{
			color: item.is_official == 1 ? '#55C964' : '#FF0000',
		}">
              {{ item.is_official == 1 ? '是' : '否' }}
            </td>
            <td>
              <td_remark :text="item.remark"/>
            </td>
            <td>
              <div class="collect_box flex" style="justify-content: center;align-items: center;">
                <p @click.stop="jumpLink(item)">查看案例</p>
<!--                <p v-if="item.is_collect == 0" class="collect" @click.stop="collectGet(item)">收藏-->
<!--                </p>-->
<!--                <p v-if="item.is_collect == 1" class="collect2" @click.stop="collectClose(item)">已收藏-->
<!--                </p>-->
              </div>
            </td>

          </tr>
          </tbody>
        </table>
        <!-- 分页器 -->
        <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>
      </div>
    </div>
    <!-- 购物车 -->
    <div v-show="shopShow == false" class="shopping_car">
      <div class="car" @click.stop="shopShow = true">
        <p>{{ CarList.length }}</p>
      </div>
    </div>
    <div :class="shopShow ? 'shop_box' : 'shop_none_box'">
      <div class="title flex" style="justify-content: space-between; align-items: center">
        <h3 class="carTitle">已选媒体: {{ CarList.length }}</h3>
        <button class="empty_but" v-show="CarList.length != 0" @click="empty()">
          清空已选媒体
        </button>
        <span class="close" @click="shopShow = false"></span>
      </div>
      <ul class="listbox" v-show="CarList.length != 0">
        <li v-for="(item, index) in CarList" :key="index">
          <!-- <img :src="item.logo" alt="" /> -->
          <div style="width:100px">
            <td_remark :text="item.title"/>
          </div>
          <p class="price">{{
              item.check == 'member_headline_price' ?
                  `头条价格:￥0` :
                  `普条价格:￥0`
            }}</p>
          <span class="deleteIcon" @click.stop="detale(item)"></span>
        </li>
      </ul>
      <el-empty v-show="CarList.length == 0" description="您还没有选择资源赶紧去看看心仪的资源吧"></el-empty>
      <div class="bottom_box">
        <div class="flex" style="justify-content: space-between; margin-bottom: 1rem">
          <p style="font-size: 1.4rem; color: #999">合计：</p>
          <p class="totalMoney">
            ￥<span>0</span>
          </p>
        </div>
        <button class="put_but" @click="putFn(item)">立即投放</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {media_screen_} from '@/util/media_screen_'
import export_excel from '@/components/export_excel.vue';
import media_screen from '@/components/media_screen.vue';

export default {
  name: '',
  computed: {
    ...mapState(['media_screen_data', 'fillActive']),
    totalMoney: function () {
      var n = 0;
      for (var i = 0; i < this.CarList.length; i++) {
        if (this.CarList[i].check == 'member_headline_price') {
          n += this.CarList[i].member_headline_price;
        } else if (this.CarList[i].check == 'ordinary_price') {
          n += this.CarList[i].ordinary_price;
        }
      }
      return n.toFixed(2);
    }
  },
  watch: {
    CarList: {
      handler() {
        this.excelPush()
      },
      deep: true,
    }
  },
  components: {
    media_screen,
    export_excel
  },
  data() {
    return {
      /*数据表格*/
      tableData: [],
      CarList: [],
      // 购物车任务栏切换
      shopShow: false,
      Allcheck: false,
      limit: 0,
      ids: [],
      fullscreenLoading: false,
      total_page: 0, //分页总页数
      page: 1,
      count: 0,
      json_fields: {
        " 媒介名称": 'title',
        "微信号": 'account',
        "头条价格": 'member_headline_price',
        "普条价格": 'ordinary_price',
        "行业类型": 'industry_classify_title',
        "案例链接": 'case_url',
        "所属区域": 'area_title',
        "可发内链": 'is_pub_url',
        "可发联系方式": 'is_pub_contact',
        "出稿速度": 'draft_speed',
        "粉丝量": 'fans_num',
        "平均阅读量": 'views',
        "官方认证": 'is_official',
        "备注": 'remark',
      },
      json_data: [],
      //请求参数
      data: {
        category_id: 4,
        collect: 0,
        page: 1,
        limit: 20,
        // is_right_package: 1
      }
    }
  },
  mounted() {
    this.get_list()
  },
  methods: {
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    onPitch(index, type) {
      this.shopShow = true
      // console.log('tableDate:', this.tableData[index].check, type);
      // this.tableData[index].check = type
      const result = this.CarList.every(item => item.id !== this.tableData[index].id);
      if (result == true) {
        this.tableData[index].check = type
        this.CarList.push(this.tableData[index])
      } else {
        this.CarList.forEach((item, i) => {
          if (this.CarList[i].id == this.tableData[index].id && this.CarList[i].check != type) {
            console.log(this.CarList[i].check);
            this.tableData[index].check = type
            this.CarList[i].check = type
          } else if (this.CarList[i].id == this.tableData[index].id && this.tableData[index].check == type) {
            console.log(2);
            this.CarList.splice(i, 1)
            this.tableData[index].check = ''
          }
        })
      }
    },
    // 清空购物车
    empty() {
      this.$confirm('是否清空已选媒体?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            this.fullscreenLoading = true
            // setTimeout(() => {
            this.CarList = []
            this.shopShow = false
            // this.toggleSelection()
            this.tableData.forEach((item, index) => {
              if (item.check) {
                console.log(item)
                return (item.check = false)
              }
            })
            this.fullscreenLoading = false
            // }, 500)
            this.$message({
              type: 'success',
              message: '已清空已选媒体',
            })
          })
          .catch(() => {
            this.$message({
              type: 'warning',
              message: '已删除',
            })
          })
    },
    excelPush() {
      this.json_data = []
      this.CarList.forEach((item, index) => {
        this.json_data.push({
          // title: item.title,
          // "微信号": 'account',
          // "价格": 'price',
          // "行业类型": 'industry_classify_title',
          // "案例链接": 'case_url',
          // "所属区域": 'area_title',
          // "可发内链": 'is_pub_url',
          // "可发联系方式": 'is_pub_contact',
          // "出稿速度": 'draft_speed',
          // "粉丝量": 'fans_num',
          // "平均阅读量": 'views',
          // "官方认证": 'is_official',
          // "备注": 'remark',
          title: item.title,
          account: item.account,
          member_headline_price: item.member_headline_price,
          ordinary_price: item.ordinary_price,
          industry_classify_title: item.industry_classify_title,
          case_url: item.case_url,
          area_title: item.area_title,
          is_pub_url: item.is_pub_url ? '是' : '否',
          is_pub_contact: item.is_pub_contact ? '是' : '否',
          draft_speed: item.draft_speed == 0 ? '不限' : item.draft_speed == 1 ? '1小时' : item.draft_speed == 2 ? '2小时' : item.draft_speed == 3 ? '3小时' : item.draft_speed == 4 ? '4小时' : item.draft_speed == 5 ? '当天' : '次日',
          fans_num: item.fans_num,
          views: item.views,
          is_official: item.is_official ? '是' : '否',
          remark: item.remark,
        })
      })


    },
    // 获取列表数据
    get_list() {
      // let data = media_screen_(this.$store.state.media_screen_data.date_form)
      // data.page = this.page
      // data.limit = 20
      this.fullscreenLoading = true
      this.curlGet('/api/medium/right_package_list', this.data).then((res) => {
        if (res.data.code) {
          this.tableData = res.data.data.list
          this.count = res.data.data.count
          this.total_page = res.data.data.total_page
          this.tableData.forEach((item, index) => {
            this.$set(this.tableData[index], 'check', '')
            this.CarList.forEach((el, i) => {
              if (el.check && item.id == el.id) {
                this.$set(this.tableData[index], 'check', el.check)
              }
            })
          })
          this.shopDate(this.tableData, this.$store.state.shopObj, this.CarList)
        }
        this.fullscreenLoading = false
      })
    },
    // 删除
    detale(element) {
      this.CarList.forEach((el, i) => {
        if (el.id == element.id) {
          this.CarList.splice(i, 1)
        }
      })
      // this.CarList.splice(index,1)
      this.tableData.forEach((item, index) => {
        if (item.id == element.id) {
          this.tableData[index].check = false
        }
      })
    },
    // 立即投放
    putFn() {
      if (this.CarList.length == 0) {
        return this.$message({
          type: 'warning',
          message: '请选择媒介',
        })
      } else if (!this.$user_info) {
        this.$message({
          type: 'warning',
          message: '请登录',
        })
      } else {
        this.fullscreenLoading = true
        this.CarList.forEach((item, index) => {
          this.CarList[index].price = this.CarList[index][this.CarList[index].check]
          console.log(this.CarList[index]);
        })
        setTimeout(() => {
          this.fullscreenLoading = false
          this.$store.commit('shopPush2', this.CarList)
          if (this.$store.state.fillActive == false) {

            this.$router.push('/user/SetMenu/ManuscriptPreview')
          } else {
            this.$router.push({
              path: '/user/SetMenu/fill?id=4',
            })
          }
        }, 500)

      }
    },
    // 收藏
    collectGet(item) {
      console.log(item);
      this.curlGet('/api/medium/collect', {
        mid: item.id,
        style: 1
      }).then(res => {
        console.log(res)
        if (res.data.code) {
          this.$message({
            message: '收藏成功',
            type: 'success'
          })
          this.get_list()
        }
      })
    },
    // 取消收藏
    collectClose(item) {
      this.$confirm('是否取消收藏?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlGet('/api/medium/collect', {
          mid: item.id,
          style: 1
        }).then(res => {
          console.log(res)
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: '取消收藏成功!'
            });
            this.get_list()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/shopCar';
@import '@/scss/overText';
@import '@/scss/user_media';
@import '@/scss/price';
</style>